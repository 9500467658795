/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #073859; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
    animation: fadeOut 1s ease-out forwards; /* Fade-out animation */
    animation-delay: 2s; /* Delay before fade-out starts */
  }
  
  .preloader-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preloader-logo {
    width: 100px; /* Initial small size */
    animation: scaleUp 2s ease-in-out forwards; /* Scale-up animation */
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(0.5); /* Start smaller */
    }
    100% {
      transform: scale(1); /* Scale to full size */
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0; /* Fade out to transparent */
      visibility: hidden; /* Hide element */
    }
  }
  