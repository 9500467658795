/* Custom fallback styles */
.fallback-loading {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7; /* Reduced opacity */
    transition: opacity 0.3s ease-in-out;
  }
  
  .fallback-loading .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #073859;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  